<template lang="pug">
  .page-color.widget.widget--min-screen-size
    .page-color__inner
      .page-color__content
        .title DRESS CODE
        p.page-color__text Мы очень старались сделать#[br] праздник красивым и будем рады,#[br] если в своих нарядах вы поддержите#[br]цветовую гамму нашей свадьбы
        .page-color__colors
          template(v-for="color in colors")
            .page-color__plate(:style="{ backgroundColor: color }")
        p.page-color__text Наш праздник будет проходить#[br] на природе, поэтому надевайте#[br] удобную обувь и захватите#[br] с собой тёплую одежду.#[br]
        p.page-color__text Мы не хотим, чтобы вы замёрзли!
    made-by-invito
</template>

<script>
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'Index',

  components: {
    MadeByInvito
  },

  data: () => ({
    colors: [
      '#BEC2CE',
      '#707E6D',
      '#5A5B56',
      '#D0D0C8'
    ]
  })
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_animations.scss';
  @import '../../sass/abstracts/_variables.scss';

  .page-color {
    padding-top: 10rem;
    padding-bottom: 8rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .title {
      padding: 1rem 0;
      text-align: center;
      margin-bottom: 4.5rem;
      border-top: .1rem solid $color-primary;
      border-bottom: .1rem solid $color-primary;
    }

    &__inner {
      width: 100%;
      opacity: 0;
      animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
    }

    &__content {
      width: 27rem;
      margin: 0 auto;
    }

    &__text {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.8rem;
      white-space: nowrap;
      margin-bottom: 4rem;
    }

    &__colors {
      display: flex;
      justify-content: center;
      margin-bottom: 4.5rem;
    }

    &__plate {
      position: relative;
      width: 3rem;
      height: 10rem;

      &:first-child {
        border: .1rem solid rgba($color-primary, .1);
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }

      &::after {
        content: "";
        opacity: .3;
        position: absolute;
        height: 100%;
        width: 100%;
        top: .5rem;
        left: .5rem;
        border: .1rem solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to bottom, #939393, #4E4E4E 90%, $color-primary);
      }
    }
  }
</style>
